import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Button, Paper, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { filter, find, isEmpty, uniq } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDataMappings } from '../dataMappings/dataMappings.actions';
import { handleToastMessage } from '../layout/layout.actions';
import { getSurveyTypes } from '../surveyTypes/surveyTypes.actions';
import QuestionForm from './questionForm.component';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import { createQuestion, deleteQuestion, getQuestions, saveQuestion } from '@survey/common/dist/actions/questions.actions';
import { getAnswers, getLanguages } from '@survey/common/dist/actions/answers.actions';
import { getEntityTypes } from '@survey/common/dist/actions/entities.actions';
import { getCountriesList } from '@survey/common/dist/actions/countries.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import { getTechnologies } from '@survey/common/dist/actions/technologies.actions';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import QuestionTranslation from '@survey/common/dist/components/dialogs/QuestionTranslation';

const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  question: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class QuestionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { showDeleteDialog: false, showTranslationDialog: false };
    this.onSubmit = this.onSubmit.bind(this);
    this.updateTranslation = this.updateTranslation.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
  }

  componentDidMount() {
      const { surveyTypes, pages, tabs, questions, answers, entityTypes, countriesList, mappings, technologies, languages } = this.props;

    isEmpty(surveyTypes) && this.props.getSurveyTypes();
    isEmpty(pages) && this.props.getPages();
    isEmpty(tabs) && this.props.getTabs();
    isEmpty(questions) && this.props.getQuestions();
    isEmpty(answers) && this.props.getAnswers();
    isEmpty(entityTypes) && this.props.getEntityTypes();
    isEmpty(countriesList) && this.props.getCountriesList();
    isEmpty(mappings) && this.props.getDataMappings();
    isEmpty(technologies) && this.props.getTechnologies();
    isEmpty(languages) && this.props.getLanguages();
  }

  async onSubmit(data) {
    if (this.props.match.params.questionId === 'new') {
      let createResponse = await this.props.createQuestion(data);
      if (createResponse.type === 'CREATE_QUESTION_SUCCESS') {
        this.props.history.goBack();
      } else {
        //Display error
      }
    } else {
      data['questionID'] = this.props.match.params.questionId;
      let createResponse = await this.props.saveQuestion(this.props.match.params.questionId, data);
      if (createResponse.type === 'SAVE_QUESTION_SUCCESS') {
        this.props.history.goBack();
      } else {
        //Display error
      }
    }
  }

  updateTranslation(data) {
    let question = find(this.props.questions, {
      questionID: parseInt(this.props.match.params.questionId, 10),
    });
    question = data;
    this.setState({ showTranslationDialog: false });
    // this.props.saveQuestion(this.props.match.params.questionId, data).then(() => {
    //   this.setState({ showTranslationDialog: false });
    // });
  }
  async deleteQuestion() {
    this.setState({ showDeleteDialog: false });
    let deleteResponse = await this.props.deleteQuestion(this.props.match.params.questionId);
    if (deleteResponse.type === 'DELETE_QUESTION_SUCCESS') {
      this.props.history.goBack();
    } else {
      //Display error
    }
  }

  render() {
    const { isLoading, classes, questions, match, surveyTypes, history, pages, tabs, answers, countriesList, entityTypes, permissions, handleToastMessage, mappings,languages } = this.props;
    let question = find(questions, {
      questionID: parseInt(match.params.questionId, 10),
    });

    let questionReferences = [];
    let branchingQuestionReferences = [];

    if (question) {
      questionReferences = filter(surveyTypes, (surveyType) => {
        let index = surveyType.questions.findIndex((i) => {
          return i.questionID === question.questionID;
        });
        return index !== -1;
      });

      questions.forEach((q) => {
        Object.values(q.branchingQuestions).forEach((val) => {
          if (val.indexOf(question.questionID) !== -1) {
            branchingQuestionReferences.push(q);
          }
        });
      });
      branchingQuestionReferences = uniq(branchingQuestionReferences);
    }

    return isLoading ? (
      <LoadingOverlay />
    ) : (
      <Paper className={classes.paper} square>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.question}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="list-ul" />
              </Avatar>
              <div>
                <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }} onClick={() => history.push('/questions')}>
                  Questions
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  Question ID: {question ? question.questionID : 'New'}
                </Typography>
              </div>
            </div>
            {question && (
              <div>
                <Button variant="contained" color="primary" className={classes.button} onClick={() => this.setState({ showTranslationDialog: true })}>
                  Translation
                </Button>
                {question.active ? (
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.onSubmit({ ...question, active: false })}>
                    De-Activate
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.onSubmit({ ...question, active: true })}>
                    Activate
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.setState({ showDeleteDialog: true })}
                  disabled={questionReferences.length > 0 || branchingQuestionReferences.length > 0}
                >
                  Delete
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <QuestionForm
          question={question}
          questions={questions}
          questionReferences={questionReferences}
          branchingQuestionReferences={branchingQuestionReferences}
          pages={pages}
          tabs={tabs}
          answers={answers}
          history={history}
          countriesList={countriesList}
          entityTypes={entityTypes}
          onSubmit={this.onSubmit}
          onError={(errors) => console.log(errors)}
          permissions={permissions}
          handleToastMessage={handleToastMessage}
          mappings={mappings}
          technologies={this.props.technologies}
        />
        <Confirm
          title="Confirm Delete"
          onClose={() => this.setState({ showDeleteDialog: false })}
          onConfirm={this.deleteQuestion}
          contentText={question ? `Please confirm that you would like to delete Question ID: ${question.questionID}` : ''}
          open={this.state.showDeleteDialog}
        />
                <QuestionTranslation onClose={() => this.setState({ showTranslationDialog: false })} question={question} onConfirm={this.updateTranslation} open={this.state.showTranslationDialog} languages={languages} />
      </Paper>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    surveyTypes: state.surveyTypes.get('surveyTypes'),
    entityTypes: state.entities.get('entityTypes'),
    pages: state.pages.get('pages'),
    tabs: state.tabs.get('tabs'),
    questions: state.questions.get('questions'),
    countriesList: state.countries.get('countriesList'),
    answers: state.answers.get('answers'),
    isLoading:
      state.pages.get('isLoading') ||
      state.tabs.get('isLoading') ||
      state.questions.get('isLoading') ||
      state.answers.get('isLoading') ||
      state.countries.get('isLoading') ||
      state.surveys.get('isLoading') ||
      state.entities.get('isLoading') ||
      state.mappings.get('isLoading'),
    permissions: state.auth.get('permissions'),
    mappings: state.mappings.get('mappings'),
    technologies: state.technologies.get('technologies'),
    languages: state.answers.get('languages')
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    getSurveyTypes,
    getPages,
    getTabs,
    getQuestions,
    createQuestion,
    saveQuestion,
    deleteQuestion,
    getAnswers,
    getEntityTypes,
    getCountriesList,
    handleToastMessage,
    getDataMappings,
    getTechnologies,
    getLanguages
  })(QuestionContainer)
);
